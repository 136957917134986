a {
  text-decoration: none;
  color: #ff5b00;
}

@media screen and (max-width: 600px) {
   .img-cover {
     width: 100%;
   }
}

@media screen and (max-width: 600px) {
  .img-news {
    width: 150px;
  }
}


.card {
  padding: 20px;
}

.pic {
  position: relative;
}

.pic-title {
  position: absolute;
  top: 20%;
  /* left: 110px; */
  left: 10%;
  color: #FFF;
  font-size: 30px;
}



.basic-right {
  span {
    color: #999;
    line-height: 25px;
  }

  a {
    text-decoration: none;
    color: #ff5b00;
  }
}

.search-input {
  padding: 4px 4px 4px 8px;
  border: 2px solid #ff5b00;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.search-card-content {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.input-placeholder {
  color: #bfbfbf;
}

.input-icon {
  color: #8c8c8c;
  margin-right: 4px;
}


.sc-card {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  margin-right: 10px;
  padding: 10px;
  background-color: #FFF;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.5s;

  img {
    width: 100%;
  }

  &:hover,&:active {
    background-color: #ff5b00;
    color: #FFF !important;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    .card-action {
      color: #ff5b00;

    }

    .card-disabled-action {
      cursor: not-allowed;
    }

    .card-title {
      color: #FFF;
    }
  }
}


.card-action {
  color: #ff5b00;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 20px;
  background: #FFF;
  border-radius: 8px;
}


.news-title {
  font-size: 18px;
  padding: 20px 0;
}

.news-desc {
  padding-bottom: 20px;
  color: #999;
  line-height: 25px;
}


.news-time {
  font-size: 12px;
}


.ad-desc {
  text-align: center;
  padding: 25px 0;
  color: #999;
  line-height: 25px;
}

.ad-title {
  font-size: 18px;
  margin-top: 10px;
}
.ad-img {
  width: 100px;
}