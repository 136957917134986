.content {
  width: 100%;

  h3 {
    text-align: center;
  }

  img {
    border-radius: 16px;
  }
}