.school {
  img {
    width: 352px;
    height: 222px;
  }
}

.title {
 
}

.desc {
  padding-bottom: 10px;
  color: #999;
}

.card-title {}