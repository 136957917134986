.content {
  width: 100%;
  border-radius: 16px;
  img {
    border-radius: 8px;
  }
}

.right {
  padding: 20px;
}

.title {
  padding: 5px 10px 5px 20px;
  margin: 10px;
  background-color: #ff5b00;
  border-radius: 20px;
  color: #FFF;
  position: relative;
  font-size: 14px;
  width: 80px;

  span{
    position: absolute;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    left: -20px;
    top: -4px;
    border-radius: 50%;
    border: 1px solid #FFF;
    content: '';
    background-color: #ff5b00;
    font-size: 16px;
  }
}

.desc {

}

.bottom {
  padding: 10px;
  font-size: 20px;
  background-color: #ff5b00;
  color: #FFF;
}

.card {
  flex: 1;
  padding: 0px 20px;
}