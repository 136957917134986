.title {
  font-size: 25px;
  font-weight: normal;
  color: #ff5b00;
}

.content {
  width: 100%;
  padding: 20px;
  padding-top: 0;
  box-sizing: border-box;
}