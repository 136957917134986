.bottom {
  background: rgb(37 42 63);
  color: #FFF;
  padding: 0 20px 20px 20px;
  span,a {
    color: #949aae;
  }

}

.bottom-title {
 
}