.student {
  h3 {
    text-align: center;
    width: 100%;
  }
}

.pic-title {
  padding-top: 20px;
  text-align: center;
}

.pic {
  text-align: center;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}

.open {
  padding-top: 20px;
  color: #999;
  cursor: pointer;
}