
.nav-bar {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}
.nav {
  display: flex;
  align-items: center;
}
.nav-logo {
 
}

.item {
  font-weight: 500;
  color: #949aae;
  padding: 0 20px;
  cursor: pointer;
}

.item-active {
  color: #ff5b00;
}

.search {

}
:global(.ant-menu-inline) {
  border: none!important;
}

