.title {
  font-size: 25px;
  text-align: center;
  padding-bottom: 30px;
}

.padding {
  padding: 10px 0;
}

.content {
  
  padding: 50px 50px;
  background-color: #f0f6ff;
}

.result {
  padding-bottom: 20px;
}

.row {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}