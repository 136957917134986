.second-top {
  position: relative;

  div {
    position: absolute;
    top: 120px;
    left: 120px;
    font-size: 20px;
    color: #FFF;
  }
}

.breadcrumb {
  background: #FFF;
  padding: 10px;
}

.left-menu {
  margin-right: 30px;
  background: #FFF;
}

.menu-item-title {

  padding: 0 50px;
  margin-bottom: 10px;
  position: relative;

  div {
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
  }
}

.menu-item {
  padding: 10px 50px;
  cursor: pointer;

  &:hover {
    background-color: #ff5b00;
    color: #FFF;
  }
}

.menu-item-active {
  padding: 10px 50px;
  cursor: pointer;
  background-color: #ff5b00;
  color: #FFF;
}

.right-content {
  flex: 1;
  background-color: #FFF;
  padding: 20px;
}

.content {
  padding-top: 20px;
}

.wrap {
  padding: 50px;
  background: #f3f3f3;
  padding-top: 20px;
}

.wrap-mobile {
  .right-content {
    padding: 0;
    margin-bottom: 20px;
  }
}

.content-title {
  text-align: center;
}