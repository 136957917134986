body {
  margin: 0;
  background-color: #f3f3f3;
}



.page {
  width: 1440px;
  background-color: #FFF;
  margin: 0 auto;
  box-sizing: border-box;
}

.page-mobile {
  background-color: #FFF;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  img {
    max-width: 100%;
  }
  .ant-menu-inline {
    border: none!important;
  }

  .pic-title {
    font-size: 20px;
    top: 10px;
  }
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: #333 !important;
}

.ant-carousel .slick-prev::before {
  color: #333 !important;
}

.slick-arrow.slick-prev {
  font-size: 30px !important;
}

.ant-carousel .slick-next::before {
  color: #333 !important;
}

.slick-arrow.slick-next {
  font-size: 30px !important;
}